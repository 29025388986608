<template>
  <XyzTransition
    :xyz="animateClasses.join(' ')"
    :mode="transitionMode"
    :appear="initialAnimation"
  >
    <slot />
  </XyzTransition>
</template>

<script>
export default {
  name: 'FadeSlideAnimation',
  props: {
    duration: {
      type: [Number, String],
      validator: (value) => {
        const allowed = [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
        if (allowed.includes(parseFloat(value))) return true;

        throw new Error(`Unsupported animation duration '${value}', allowed: ${allowed}`);
      }
    },
    initialAnimation: {
      type: Boolean,
      default: true
    },
    leaveFirst: {
      type: [Boolean, undefined],
      default: undefined
    },
    directionForward: {
      type: [Boolean, undefined],
      default: undefined
    },
    directionTypeLeftRight: { // left /right || top /bottom
      type: Boolean,
      default: true
    },
    additionalAnimateClasses: {
      type: String,
      default: ''
    }
  },

  computed: {
    animateClasses() {
      return [
        'fade',
        this.animationDuration(),
        ...this.animationDirection(),
        this.additionalAnimateClasses || ''
      ];
    },

    transitionMode() {
      if (this.leaveFirst === undefined) return undefined;
      return this.leaveFirst ? 'out-in' : 'in-out';
    }
  },

  methods: {
    animationDuration() {
      let duration = 'duration';

      if (this.duration === undefined) return duration;

      duration += `-${this.duration}`;
      return duration;
    },

    animationDirection() {
      const direction = ['ease-in', ''];
      if (this.directionForward === undefined) return direction;

      const directions = this.directionTypeLeftRight
        ? ['left', 'right']
        : ['up', 'down']
      ;

      direction[0] = `in-${this.directionForward ? directions[1] : directions[0]}`;
      direction[1] = `out-${this.directionForward ? directions[0] : directions[1]}`;

      return direction;
    },
  }
};
</script>

<style scoped>
</style>
