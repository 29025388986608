<template>
  <v-container class="my-3">
    <PagePreLoader
      :disable-scroll="true"
      :loading="isLoading"
    />

    <v-row>
      <!--left content-->
      <v-col
        cols="12"
        lg="3"
      >
        <BlogCategories
          :selected-category-id="selectedCategoryId"
          @valueChanged="handleCategorySelect"
        />

        <FadeSlideAnimation>
          <v-card
            v-if="mostViewedPost"
            class="mt-5"
          >
            <v-card-title class="secondary pa-2 white--text">
              Recommended Read
            </v-card-title>

            <v-card-text class="pa-0">
              <blog-simple
                :post="mostViewedPost"
              />
            </v-card-text>
          </v-card>
        </FadeSlideAnimation>
      </v-col>

      <!--blog posts-->
      <v-col
        cols="12"
        lg="9"
      >
        <ListAnimation
          duration="2"
          stack-disabled
          initial-animation
          direction-scale="0"
        >
          <div
            v-for="post in blogPosts"
            :key="post.id"
            class="mb-3 transition-all-0_5s xyz-out-absolute"
          >
            <blog-single
              :post="post"
            />
          </div>
        </ListAnimation>

        <!--pagination-->
        <v-row
          v-if="pageLength > 1"
          class="mt-3"
        >
          <v-col
            cols="12"
            md="6"
            offset-md="3"
          >
            <v-card outlined>
              <div
                class="text-center align-content-center justify-center pa-3"
              >
                <v-pagination
                  v-model="pageIndex"
                  :length="pageLength"
                  circle
                  color="secondary"
                  @input="fetchBlogPosts"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BlogSingle from '@/components/Blog/BlogSingle';
import BlogSimple from '@/components/Blog/BlogSimple';
import BlogCategories from '@/components/Blog/BlogCategories';
import { collect } from 'collect.js';
import FadeSlideAnimation from '@/components/Utils/Animations/FadeSlideAnimation';
import ListAnimation from '@/components/Utils/Animations/ListAnimation';
import { createNamespacedHelpers } from 'vuex';
import PagePreLoader from '@/components/Utils/PagePreLoader';

const { mapActions } = createNamespacedHelpers('blog');

export default {
  name: 'Blogs',
  components: { PagePreLoader, ListAnimation, FadeSlideAnimation, BlogCategories, BlogSimple, BlogSingle },
  data: () => ({
    pageIndex: 1,
    selectedCategoryId: null
  }),

  computed: {
    isLoading() {
      return this.$store.state.blog.loading;
    },

    blogPosts() {
      return this.$store.state.blog.data.posts || [];
    },

    pageLength() {
      return this.$store.state.blog.data.pageCount;
    },

    mostViewedPost() {

      if (!this.blogPosts.length) return false;

      return collect(this.blogPosts).random(1).first();
    }
  },

  watch: {
    selectedCategoryId: {
      handler() {
        // reset page index
        this.pageIndex = 1;

        this.fetchBlogPosts();
      },
      immediate: true
    },

    loading: {
      handler() {
        window.scrollTo(0, 0);
      },
      immediate: true
    },

    blogPosts: {
      handler() {
        // this.setCategoryIdFromUrl();
      }
    }
  },

  mounted() {
    this.setCategoryIdFromUrl();

    // when no blog post available
    if (!this.blogPosts.length) {
      this.fetchBlogPosts();
    }
  },

  methods: {
    ...mapActions({
      getBlogPost: 'getBlogPosts'
    }),

    handleCategorySelect(category) {
      this.selectedCategoryId = category?.id || null;
    },

    fetchBlogPosts() {
      this.getBlogPost({
        categoryId: this.selectedCategoryId,
        pageIndex: this.pageIndex
      });
    },

    getCategorySlugFromUrl() {
      return this.$route.query?.category || null;
    },

    setCategoryIdFromUrl() {
      const categorySLug = this.getCategorySlugFromUrl();

      if (!categorySLug) return;

      const category = this.$store.state.blog.data.categories?.find(cat => cat.slug === categorySLug);

      if (!category) return;

      this.selectedCategoryId = category.id;
    }
  }
};
</script>
